import React from 'react';
import { SEO, Layout, Header, Footer } from '../../components';
import { translations } from './translations';
import './index.scss';

export default ({ pageContext: ctx }) => {
  const { translationPath, phone, email, metadata, i18n_lang: lang } = ctx;
  //

  const PhoneContact = () => (
    <p className="phoneContact">
      <strong>{translations[lang].phone}</strong>
      <span>{phone}</span>
    </p>
  );

  const EmailContact = () => (
    <p className="emailContact">
      <strong>{translations[lang].email}</strong>
      <span>{email}</span>
    </p>
  );

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <div className="contact-us">
        <Header
          {...{ lang, heading: translations[lang].heading }}
          lang={lang}
          translationPath={translationPath}
          PhoneContact={PhoneContact}
          EmailContact={EmailContact}
        />
        <Footer lang={lang} />
      </div>
    </Layout>
  );
};
