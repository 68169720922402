export const translations = {
  en: {
    heading: 'Contact us',
    email: 'Email',
    phone: 'Phone/WhatsApp',
  },
  ge: {
    heading: 'Kontakt',
    email: 'Email',
    phone: 'Telefon/WhatsApp',
  },
};
